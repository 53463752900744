@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.modalContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: $boxshadow;
    padding: spacing(4);
    width: min-content;
    min-width: 500px;
    box-sizing: border-box;
    border-radius: $borderRadius;
    &.variantClose{
        display: flex;
        flex-direction: column;
        padding: 0;
        & .header{
            display: flex;
            align-items: center;
            padding: spacing(0) spacing(1) 0 spacing(3);
            background: #1A1C21;
            border-top-left-radius: $borderRadius;
            border-top-right-radius: $borderRadius;
            & .close{
                margin: 0 0 0 auto;
                & .icon{
                    width: 24px;
                    color: #FCFCFC;
                }
            }
        }
    }
    &.black{
        background-color: #1A1C21;
    }
    &.white{
        background-color: #FCFCFC;
    }
}
.modalContainer:focus-visible{
    outline: none;
}