@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";
@import "../../../styles/mixins.scss";


.leadCardContainer {
    padding: spacing(2);
    overflow: hidden;

    & .filter {
        justify-self: end;
        & .button {
            border: 0.125rem solid #F3F3F3 !important;
            margin: 8px;
            max-width: 100px;
            max-height: 40px;
        }

        & .border {
            background: $fiveBackgroundColor !important;
            font-weight: bold !important;
        }
    }
}


.leadsLoader {
    margin: 0 0 0 auto;
    width: 20px !important;
    height: 20px !important;
}

.loader {
    flex: 1;
    margin: auto;
}

.leadsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 75vh;
    gap: spacing(2);
    overflow: auto;

    & .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: spacing(2) spacing(4);
        padding: 0 spacing(1) 0 0;
    }

    & .list {
        flex: 1;
        display: flex;

        &>.leadColumnContainer {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 0 0 spacing(1);
            border-radius: spacing(1);
            background-color: #F3F3F3;
            margin-right: spacing(2);

            &.removeMargin {
                margin-right: 0;
            }

            &>.itemsContainer {
                padding: 16px;
                flex: 1;
                padding-top: 0px;
            }
        }

        & .leadTitle {
            text-align: left;
            margin: spacing(2) spacing(2) 0;
            font-size: $normalFontSize;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: spacing(2);
            border-radius: spacing(1);
            min-width: 220px;
        }

        & .ball {
            // width: 24px;
            // height: 24px;
            padding: spacing(.2) spacing(1);
            border-radius: spacing(2);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        & .columnCount {
            color: $secondFontColor;
            font-size: $smallFontSize;
        }
    }
}