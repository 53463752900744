@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.container{
    padding: spacing(2.5) spacing(2) spacing(9);
    margin: 0 auto;
    &.myPublication{
        padding: 0;
        & .grid{
            padding: spacing(11) spacing(8) spacing(5);
            gap: spacing(7);
        }
    }
    & .item{
        & > .title{
            font-size: $mediumFontSize;
            font-weight: 600;
            color: $thirdFontColor;
            margin: 0;
        }
    }
    & .grid{
        position: relative;
        display: grid;
        grid-template-columns: 1fr minmax(auto, 310px);
        grid-auto-flow: row;
        grid-auto-rows: min-content;
        gap: spacing(7) spacing(8);
        margin: 0 auto;
        padding: spacing(8) spacing(8) spacing(10);
        max-width: 1160px;
        background: $backgroundColor;
        border-radius: $borderRadius;
        border: 1px solid rgba(0, 0, 0, 0.12);
        & > .slider{
            position: relative;
            grid-column: 1 / 2;
            grid-row: 1 / 3;
            max-width: 736px;
            width: 100%;
            height: 560px;
            & .certified{
                position: absolute;
                top: spacing(2);
                right: spacing(2);
                z-index: 4;
            }
        }
        & > .information{
            position: relative;
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            display: grid;
            grid-template-columns: 1fr auto auto;
            grid-auto-rows: min-content;
            grid-auto-flow: row;
            align-items: flex-start;
            gap: spacing(2) 0;
            & .lineResponsive{
                grid-column: 1 / 3;
                display: none;
                margin: 0 auto;
                width: 100%;
                height: 1px;
                background: $thirdFontColor;
            }
            & .iconButton img{
                width: 1.5rem;
            }
            & .share{
                grid-column: 2 / 3;
            }
            & .favorite{
                grid-column: 3 / 4;
            }
            & .share,
            & .favorite{
                display: flex;
                align-items: center;
                gap: spacing(2);
                & .responsive{
                    display: none;
                    font-size: $extraSmallFontSize;
                    font-weight: 400;
                    color: $fontColor;
                    margin: 0;
                }
            }
            & > p{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: spacing(1);
                margin: 0;
                & :not(.oldPrice){
                    color: $fontColor;
                }
                & .name{
                    font-size: $mediumFontSize;
                    font-weight: 500;
                    max-width: 200px;
                }
                & .description,
                & .oldPrice{
                    font-size: $normalFontSize;
                    font-weight: 400;
                }
                & .oldPrice{
                    text-decoration: line-through;
                    color: $thirdFontColor;
                }
                & .actualPrice{
                    font-size: $largeFontSize;
                    font-weight: 500;
                }
            }
        }
        & > .frequentQuestions{
            grid-column: 2 / 3;
            grid-row: 2 / 6;
            display: flex;
            flex-direction: column;
            gap: spacing(2.5);
            margin: spacing(-3) 0 0;
            max-width: 310px;
            & p{
                margin: 0;
            }
            & .title{
                font-size: $smallFontSize;
                font-weight: 600;
                color: $thirdFontColor;
            }
            & .item{
                display: flex;
                flex-direction: column;
                gap: spacing(0.5);
                font-weight: 400;
                color: $fontColor;
                & .question{
                    font-size: $extraSmallFontSize;
                }
                & .response{
                    display: flex;
                    flex-direction: column;
                    font-size: $extraExtraSmallFontSize;
                }
            }
            & .link{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: spacing(0.5) spacing(1);
                font-size: $extraSmallFontSize;
                font-weight: 400;
                & .question{
                    color: $fontColor;
                }
                & .redirect{
                    color: $primaryColor;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
        & > .linzeAiItem{
            grid-column: 1 / 2;
            display: flex;
            flex-direction: column;
            gap: spacing(1);
            & .content{
                display: flex;
                flex-direction: column;
                gap: spacing(1);
                max-width: 80%;
                & p{
                    display: flex;
                    gap: spacing(0.5);
                    font-size: $smallFontSize;
                    font-weight: 400;
                    color: $fontColor;
                    margin: 0;
                }
            }
        }
        & .linzeAiMessage{
            grid-column: 1 / 2;
            display: flex;
            flex-direction: column;
            gap: spacing(1.25);
            padding: 0 0 spacing(4);
            border-bottom: 1px solid $fourBackgroundColor;
            & p{
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: $extraSmallFontSize;
                font-weight: 400;
                color: $fontColor;
                margin: 0;
                text-align: center;
            }
        }
        & > .caracteristics{
            grid-column: 1 / 2;
            display: flex;
            flex-direction: column;
            gap: spacing(2);
            & .content{
                display: flex;
                flex-wrap: wrap;
                gap: spacing(4) spacing(6);
            }
        }
        & > .conforts{
            grid-column: 1 / 2;
            display: flex;
            flex-direction: column;
            gap: spacing(2);
            & .content{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-auto-flow: row;
                gap: spacing(4) spacing(6);
            }
        }
        & > .tags{
            grid-column: 1 / 2;
            display: flex;
            flex-direction: column;
            gap: spacing(2);
            & .content{
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                gap: spacing(2) spacing(3);
                max-width: 832px;
            }
        }
    }
    & .similars{
        display: flex;
        flex-direction: column;
        gap: spacing(2);
        margin: spacing(8) auto 0;
        max-width: 1250px;
        width: 100%;
        &.noArrows{
            max-width: 1290px;
        }
        & .content{
            position: relative;
            align-items: center;
            gap: spacing(2.5) spacing(3);
            width: 100%;
        }
    }
}

@media screen and (max-width: 1240px){
    .container{
        & .grid{
            padding: spacing(6) spacing(4);
        }
    }
}

@media screen and (max-width: 1024px){
    .container{
        padding: 0;
        & .item{
            & > .title{
                font-size: $smallFontSize;
            }
        }
        & .similars{
            display: none;
        }
        & .grid{
            display: flex;
            flex-direction: column;
            padding: 0 0 spacing(6);
            gap: spacing(3);
            & > .slider{
                max-width: 100%;
                height: auto;
                & .certified{
                    position: absolute;
                    top: auto;
                    left: spacing(2);
                    right: auto;
                    bottom: spacing(2);
                    z-index: 10;
                }
            }
            & > .verticalLine{
                display: none;
            }
            & > .information{
                grid-template-columns: repeat(2, 1fr);
                gap: spacing(2) 0;
                padding: spacing(2) spacing(3.25) 0;
                margin: 0;
                & .lineResponsive{
                    display: flex;
                }
                & .share{
                    grid-column: 1 / 2;
                    grid-row: 3 / 4;
                    margin: 0 auto;
                    &.onlyAdmin{
                        grid-column: 1 / 3;
                    }
                }
                & .favorite{
                    grid-column: 2 / 3;
                    grid-row: 3 / 4;
                    margin: 0 auto;
                }
                & .share,
                & .favorite{
                    gap: spacing(0.5);
                    & .responsive{
                        display: flex;
                    }
                }
                & > p{
                    grid-column: 1 / 3;
                    grid-row: 1 / 2;
                    gap: spacing(0.5);
                    margin: 0;
                    & :not(.oldPrice){
                        color: $fontColor;
                    }
                    & .name{
                        font-size: $mediumFontSize;
                        width: auto;
                    }
                    & .description{
                        font-size: $smallFontSize;
                    }
                    & .oldPrice{
                        font-size: $extraSmallFontSize;
                    }
                }
            }
            & > .frequentQuestions{
                display: none;
            }
            & > .linzeAiItem{
                padding: 0 spacing(3.25);
                gap: spacing(2);
                & .content{
                    gap: spacing(1);
                    & p{
                        font-size: $extraSmallFontSize;
                    }
                }
            }
            & .linzeAiMessage{
                padding: 0 spacing(3.25);
                gap: spacing(1.25);
                & p{
                    font-size: $extraSmallFontSize;
                }
            }
            & > .caracteristics{
                padding: 0 spacing(3.25);
                gap: spacing(1.5);
                & .content{
                    max-width: 100%;
                    gap: spacing(2) spacing(4);
                }
            }
            & > .conforts{
                gap: spacing(1.5);
                padding: 0 spacing(3.25);
                & .content{
                    display: flex;
                    flex-wrap: wrap;
                    gap: spacing(2) spacing(4);
                    max-width: 100%;
                }
            }
            & > .tags{
                gap: spacing(1.5);
                padding: 0 spacing(3.25);
                & .content{
                    gap: spacing(1) spacing(2);
                    max-width: 100%;
                }
            }
        }
    }
}