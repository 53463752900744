@import "../../../../styles/variables.scss";
@import "../../../../styles/functions.scss";

.cardContainer{
     position: relative;
     display: flex;
     flex-direction: row;
     margin: spacing(2) 0;
     padding: spacing(1) 0 spacing(1) spacing(2);
     background-color: #FCFCFC;
     border-radius: spacing(1);
     text-overflow: ellipsis;
     overflow: hidden;
     white-space: nowrap;
     // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
     &.fake{
          visibility: hidden;
     }
     & > .leftContainer{
          display: flex;
          flex-direction: column;
          margin-right: spacing(2);
          align-items: center;
          justify-content: center;
          & > .cardPicture{
               width: 90px;
               height: 60px;
               border-radius: 8px;
               object-fit: cover;
          }
          & > .plateContainer{
               display: flex;
               flex-direction: row;
               border: 1px solid $thirdFontColor;
               border-radius: 4px;
               padding: 2px 4px;
               margin-top: spacing(1);
               & > p{
                    margin: 0;
                    color: $thirdFontColor;
                    font-size: $extraSmallFontSize;
               }
               & > .platePoint{
                    margin: 0 2px;
                    margin-top: -4px;
               }
          }
     }
     & > .rightContainer{
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          & > p{
              
          }
          & > .title{
               margin: spacing(1) 4px 4px 0;
               font-size: $extraSmallFontSize;
               text-overflow: ellipsis;
               overflow: hidden;
               white-space: nowrap;
               width: 172px;
          }
          & > .price{
               margin: 0;
               font-size: $smallFontSize;
               font-weight: 500;
               margin-bottom: 4px;
          }
          & > .status{
               margin: 0;
               font-size: $extraSmallFontSize;
               color: #363939;
          }
          & > .userContainer{
               display: flex;
               flex-direction: row;
               align-items: center;
               & > .picture{
                    width: 18px;
                    height: 18px;
                    border-radius: 14px;
                    background-color: #D9D9D9;
                    padding: 2px;
               }
               & > p{
                    margin: 0;
                    font-size: 15px;
                    font-weight: 500;
                    margin-left: 4px;
               }
          }
          & > .bottomContainer{
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               margin-top: 4px;
               & > .bottomLeftContainer{
                    & > p:first-child{
                         margin: 0;
                         font-size: $extraExtraSmallFontSize;
                         text-overflow: ellipsis;
                         overflow: hidden;
                         white-space: nowrap;
                    }
                    & > p:last-child{
                         margin: 4px 0 0 0;
                         font-size: $extraExtraSmallFontSize;
                         color: $thirdFontColor;
                         text-overflow: ellipsis;
                         overflow: hidden;
                         white-space: nowrap;
                    }
               }
               & > .priority{
                    width: 32px;
                    color: $fontColor;
                    padding-right: spacing(2);
                    position: absolute;
                    bottom: 4px;
                    right: 0px;
                    &.high{
                         color: #FF0000;
                    }
                    &.low{
                         color: #4874D0;
                    }
                    &.medium{
                         color: #FFD600;
                    }
               }
          }
     }
}
.cardContainer:hover{
     cursor: pointer;
     background-color: #f4f5f7;
}