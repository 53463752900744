@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';

.container{
    flex: 1;
    display: flex;
    height: 100%;
    & .loader{
        flex: 1;
        margin: auto;
    }
    & > .content{
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: spacing(3);
        padding: spacing(2) spacing(2) spacing(4);
        & > .header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: spacing(4);
            & .iconButton{
                font-size: $mediumFontSize;
            }
        }
    }
}