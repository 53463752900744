@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.sidebar{
    min-width: 264px;
    background-color: #1A1C21;
    & .list{
        flex: 1;
        background-color: #1A1C21;
        transition: min-width .5s, width .5s;
        & .subList{
            background-color: #15161a;
        }
        & .item{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-transform: none;
            gap: spacing(1);
            margin: spacing(2) 0 0;
            padding: spacing(1) spacing(3);
            height: 100%;
            min-height: 48px;
            text-align: start;
            &.selected{
                background: #2d2f34;
                color: #FCFCFC !important;
                & .icon{
                    color: #FCFCFC;
                }
            }
            & .text{
                font-weight: 400;
                color: #b7b7b7 !important;
                margin: 0;
                & span{
                    font-size: $extraSmallFontSize;
                }
            }
            & .arrow{
                font-size: $mediumFontSize;
                color: #b7b7b7;
            }
            & .icon{
                margin: 0;
                min-width: auto;
                font-size: $mediumLargeFontSize;
                color: #b7b7b7;
            }
            &:hover{
                background: rgba($color: #FCFCFC, $alpha: 0.05);
            }
            &.blue{
                color: $primaryColor !important;
                & .icon{
                    color: $primaryColor !important;
                }
            }
        }
    }
}

.sidebar[data-isInLeads=true]{
    min-width: 56px;
    & .list{
        & .item{
            justify-content: center;
            padding: spacing(1) spacing(2);
            &:hover{
                & .text{
                    left: 120%;
                    visibility: visible;
                    opacity: 1;
                    transition: 0.3s ease-in-out all;
                    z-index: 4;
                }
            }
            & .arrow{
                display: none;
            }
            & .text{
                position: absolute;
                visibility: hidden;
                opacity: 0;
                left: 60px;
                transition: 0.3s ease-in-out all;
                padding: spacing(0.5) spacing(1);
                background: #1A1C21;
                border-radius: $borderRadius;
                z-index: 0;
                white-space: pre;
            }
        }
    }
}

@media (max-width: 1200px){
    .sidebar{
        min-width: 56px;
        & .list{
            & .item{
                justify-content: center;
                padding: spacing(1) spacing(2);
                &:hover{
                    & .text{
                        left: 120%;
                        visibility: visible;
                        opacity: 1;
                        transition: 0.3s ease-in-out all;
                        z-index: 4;
                    }
                }
                & .arrow{
                    display: none;
                }
                & .text{
                    position: absolute;
                    visibility: hidden;
                    opacity: 0;
                    left: 60px;
                    transition: 0.3s ease-in-out all;
                    padding: spacing(0.5) spacing(1);
                    background: #1A1C21;
                    border-radius: $borderRadius;
                    z-index: 0;
                    white-space: pre;
                }
            }
        }
    }
}