@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.container{
    flex: 1;
    display: flex;
    height: 100%;
    & .loader{
        flex: 1;
        margin: auto;
    }
    & > .content{
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: spacing(3);
        padding: spacing(2) spacing(2) spacing(4);
        & > .header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: spacing(4);
            & .iconButton{
                font-size: $normalFontSize;
            }
            & .inputSearch{
                max-width: 350px;
            }
        }
        & > .list{
            flex: 1;
            display: flex;
            & .noData{
                flex: 1;
                margin: auto;
            }
        }
        & .loadingTable{
            width: 100%;
            height: 954px;
            background: #ccc
        }
        & .table{
            @include table;
            & .row{
                & .text{
                    text-align: start;
                }
                & .state{
                    @include chips;
                    font-size: $extraExtraSmallFontSize !important;
                    font-weight: 500;
                    padding: spacing(0.75) spacing(1.25);
                    width: min-content;
                    border-radius: $borderRadius;
                    white-space: pre;
                }
                & .action{
                    justify-content: end;
                    padding: 0 spacing(4) 0 0;
                    & .icon{
                        font-size: $mediumFontSize !important;
                        &.disabled{
                            font-size: $largeFontSize !important;
                        }
                        &.green{
                            color: $successColor;
                        }
                        &.red{
                            color: $errorColor;
                        }
                    }
                }
            }
        }
        & .pagination{
            margin: 0 auto;
        }
    }
}