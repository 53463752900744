@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";
@import "../../../styles/mixins.scss";

.modal{
    max-width: 1200px;
    width: 100% !important;
}
.container{
    position: relative;
    padding: spacing(2) spacing(2) spacing(4);
    height: 60vh;
    & .loader,
    & .noData{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        height: 100%;
    }
    & .table{
        @include table;
        & .row{
            cursor: pointer;
            & .text{
                text-align: start;
                white-space: pre;
            }
            & .state{
                @include chips;
                font-size: $extraExtraSmallFontSize !important;
                font-weight: 500;
                padding: spacing(0.75) spacing(1.25);
                width: min-content;
                border-radius: $borderRadius;
                white-space: pre;
            }
        }
    }
    & .pagination{
        margin: auto auto 0;
    }
}