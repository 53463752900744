@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.button{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400 !important;
    width: 280px;
    height: 48px;
    border-radius: $borderRadius !important;
    text-transform: none !important;
    cursor: pointer;
    &.icon{
        width: auto !important;
        min-width: auto;
        gap: spacing(0.5);
    }
    &.small{
        width: 186px;
    }
    &.primary{
        background: $thirdBackgroundColor !important;
        border: 0.125rem solid $thirdBackgroundColor !important;
        &.icon{
            color: $secondFontColor;
        }
        & > p{
            color: $secondFontColor;
        }
        &:hover{
            background: rgba($color: #1A1C21, $alpha: 0.9) !important;
        }
    }
    &.secondary{
        background: $backgroundColor !important;
        border: none;
        &.icon{
            color: $mainFontColor;
        }
        & > p{
            color: $mainFontColor;
        }
        &:hover{
            background: rgba($color: #FCFCFC, $alpha: 0.9) !important;
        }
        & .loader{
            color: $mainFontColor !important;
        }
    }
    &.tertiary{
        background: none !important;
        border: 0.125rem solid $thirdBackgroundColor !important;
        &.icon{
            color: $mainFontColor;
        }
        & > p{
            color: $mainFontColor;
        }
        &:hover{
            background: rgba($color: #1A1C21, $alpha: 0.05) !important;
        }
        & .loader{
            color: $mainFontColor !important;
        }
    }
    &.fourtuary{
        background: none !important;
        border: 0.125rem solid $secondBackgroundColor !important;
        &.icon{
            color: $secondFontColor;
        }
        & > p{
            color: $secondFontColor;
        }
        &:hover{
            background: rgba($color: #1A1C21, $alpha: 0.05) !important;
        }
    }
    &.disabled{
        background: $secondBackgroundColor !important;
        border: none !important;
        &.icon{
            color: $thirdFontColor;
        }
        & > p{
            color: $thirdFontColor !important;
        }
        &.secondary{
            background: #494B50 !important;
        }
    }
    &.text{
        width: auto;
        height: auto;
        background: none !important;
        border: none !important;
        &:hover{
            background: rgba($color: #1A1C21, $alpha: 0.05) !important;
        }
        &:active{
            background: rgba($color: #1A1C21, $alpha: 0.1) !important;
        }
        &.icon{
            color: $fontColor;
        }
        & > p{
            color: $fontColor;
        }
        & .loader{
            color: $thirdBackgroundColor;
        }
    }
    & .loader{
        width: 24px !important;
        height: 24px !important;
        color: $backgroundColor;
        &.icon{
            width: 18px !important;
            height: 18px !important;
            margin: 0 spacing(0.75) 0 0;
        }
    }
    & > p{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        &.normal{
            font-size: $smallFontSize;
        }
        &.small{
            font-size: $extraSmallFontSize;
        }
    }
}