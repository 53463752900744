@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.caracteristicContainer {
    display: flex;
    align-items: center;
    gap: spacing(1.5);
    & img{
        width: 40px;
    }
    & p{
        display: flex;
        flex-direction: column;
        margin: 0;
        gap: spacing(1);
        font-size: $extraSmallFontSize;
        color: $fontColor;
        & .title{
            font-weight: 300;
        }
        & .value{
            font-weight: 500;
        }
    }
}

.skeleton{
    width: 120px !important;
    height: 40px !important;
}

@media screen and (max-width: 1024px) {
    .caracteristicContainer {
        & img{
            width: 20px;
        }
        & p{
            gap: 0;
            font-size: $extraSmallFontSize;
        }
    }
}