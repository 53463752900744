@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';

.additionalCosts{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: spacing(1.5);
    margin: spacing(1.5);
    width: 100%;
}
.cardContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: min-content;
    background-color: $backgroundColor;
    border-radius: $borderRadius;
    &.open{
        & .header{
            & .arrow{
                transform: rotate(180deg);
            }
        }
        & > .content{
            display: grid;
        }
    }
    & > .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: spacing(2);
        padding: spacing(2);
        cursor: pointer;
        border-radius: $borderRadius;
        &:hover{
            background: rgba($color: #000000, $alpha: 0.075);
        }
        & > p{
            display: flex;
            flex-direction: column;
            color: $fontColor;
            margin: 0;
            width: 100%;
            user-select: none;
            & > .title{
                font-size: $smallFontSize;
                font-weight: 600;
            }
            & > .description{
                font-size: $extraSmallFontSize;
                font-weight: 400;
            }
        }
        & .loader{
            width: 20px !important;
            height: 20px !important;
            color: $fontColor;
        }
        & .valid{
            font-size: $mediumFontSize;
            color: $fontColor;
        }
        & .arrow{
            & .icon{
                font-size: $normalFontSize;
                transform: rotate(270deg);
            }
        }
    }
    & > .content{
        display: none;
        gap: spacing(2);
        padding: spacing(2);
        & > .title{
            font-size: $smallFontSize;
            font-weight: 400;
            color: $fontColor;
            margin: 0;
        }
        & > .grid{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-auto-flow: row;
            gap: spacing(2);
        }
        & .saveButton{
            margin: 0 auto 0 0;
            & .iconButton{
                font-size: $mediumFontSize;
            }
        }
    }
}