@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.formControl{
    position: relative;
    & ::-webkit-outer-spin-button,
    & ::-webkit-inner-spin-button{
        -webkit-appearance: none;
    }
    &.white{
        &.normal{
            & .input{
                color: $secondFontColor !important;
            }
        }
        & .passwordValidations{
            & .message{
                color: $secondFontColor;
            }
            & .item{
                & p{
                    color: $secondFontColor;
                }
            }
        }
    }
    & .loader{
        width: 20px;
        height: 20px;
        color: $primaryColor;
    }
    & .bottomMessage{
        position: absolute;
        margin: spacing(0.5) 0 0;
        top: 100%;
        left: 0;
        display: flex;
        justify-content: space-between;
        font-size: $extraExtraSmallFontSize;
        font-weight: 400;
        & .message{
            color: $thirdFontColor;
            white-space: nowrap;
            margin: 0;
        }
        & .error{
            color: $errorColor;
            margin: 0;
            white-space: nowrap;
        }
    }
    & .onlyMessage{
        position: absolute;
        top: 100%;
        left: 0;
        font-size: $extraExtraSmallFontSize;
        font-weight: 400;
        color: $thirdFontColor;
        margin: 0;
        white-space: nowrap;
    }
    & .onlyError{
        position: absolute;
        top: 100%;
        left: 0;
        font-size: $extraExtraSmallFontSize;
        font-weight: 400;
        color: $errorColor;
        margin: 0;
        white-space: nowrap;
    }
    &.normal{
        & .label{
            color: #8E8E8E !important;
        }
        & .input{
            background: none !important;
            outline: solid 1px $fontColor;
            outline-offset: -1px;
            border-radius: 4px;
            color: $mainFontColor;
            & .startAdornment{
                & p{
                    color: $mainFontColor;
                }
            }
        }
        & .focus{
            outline: solid 2px $fontColor;
            outline-offset: -2px;
        }
    }
    & .showValue{
        & .icon{
            width: 24px;
            color: $thirdFontColor;
        }
    }
    & .helperText{
        position: absolute;
        top: 56px;
        white-space: nowrap;
    }
}


.inputBorderless{
    display: flex;
    align-items: center;
    justify-content: center;
    &.fullWidth{
        width: 100%;
    }
    & p{
        font-weight: 400;
        color: $fontColor;
        margin: 0;
        &.symbol{
            font-size: $largeFontSize;
        }
    }
    & .input{
        width: auto;
        padding: 0;
        font-size: $largeFontSize;
        font-weight: 500;
        color: $fontColor;
        text-align: center;
        background: none;
        border: none;
        outline: none;
    }
}

.inputSearch{
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    width: 100%;
    background: $secondFontColor;
    border-radius: 12px;
    border: 1px solid $borderColor;
    z-index: 2;
    & .icon{
        width: 28px;
        color: $fontColor;
    }
    & .input{
        padding: spacing(2) spacing(1.75) spacing(2) spacing(2);
        font-size: $smallFontSize;
        font-weight: 400;
        color: $fontColor;
        width: 100%;
        outline: none;
        border: none;
        background: none;
        &::placeholder{
            color: $thirdFontColor;
        }
    }
}

.passwordValidations{
    display: flex;
    flex-direction: column;
    gap: spacing(1);
    margin: spacing(0.5) 0 0;
    & .message{
        font-size: $extraExtraSmallFontSize;
        font-weight: 400;
        color: $thirdFontColor;
        margin: 0;
        white-space: nowrap;
    }
    & .item{
        display: flex;
        align-items: center;
        gap: spacing(1);
        & p{
            font-size: $extraExtraSmallFontSize;
            font-weight: 400;
            color: $fontColor;
            margin: 0;
        }
    }
}

.inputLocation{
    position: relative;
    width: 100%;
    & .input{
        z-index: 4;
    }
    & .locationSelector{
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 3;
    }
}