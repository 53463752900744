@import '../../../../styles/functions.scss';

.skeletonContainer{
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-auto-rows: min-content;
    grid-auto-flow: row;
    align-items: flex-start;
    gap: spacing(2);
    width: 100%;
    & .icon{
        width: 32px;
        height: 32px;
    }
    & .lineResponsive{
        grid-column: 1 / 3;
        display: none;
        margin: 0 auto;
        width: 90%;
        height: 1px;
        background: $thirdFontColor;
    }
    & > .data{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: spacing(1);
        margin: 0 0 spacing(2);
        width: 200px;
        & .name{
            font-size: $mediumFontSize;
            width: 100%;
        }
        & .description,
        & .oldPrice{
            font-size: $normalFontSize;
            width: 100%;
        }
        & .actualPrice{
            font-size: $largeFontSize;
            width: 100%;
        }
    }
    & > .button{
        grid-column: 1 / 3;
        height: 48px;
    }
}

@media screen and (max-width: 1024px){
    .skeletonContainer{
        grid-column: 1 / 3;
        grid-template-columns: repeat(2, 1fr);
        gap: spacing(2) 0;
        margin: 0;
        padding: spacing(2) spacing(3.25) 0;
        width: auto;
        & .lineResponsive{
            display: flex;
        }
        & .icon{
            width: 32px;
            height: 32px;
        }
        & .share{
            grid-column: 1 / 2;
            grid-row: 3 / 4;
            margin: 0 auto;
        }
        & .favorite{
            grid-column: 2 / 3;
            grid-row: 3 / 4;
            margin: 0 auto;
        }
        & > .data{
            grid-column: 1 / 3;
            gap: spacing(0.5);
            margin: 0;
            & .description{
                font-size: $smallFontSize;
            }
            & .oldPrice{
                font-size: $extraSmallFontSize;
            }
        }
        & > .button{
            margin: 0 auto;
            width: 180px;
            &.cash{
                grid-column: 1 / 2;
            }
            &.credit{
                grid-column: 2 / 3;
            }
        }
    }
}

@media screen and (max-width: 450px){
    .skeletonContainer{
        & > .button{
            &.cash{
                grid-column: 1 / 3;
            }
            &.credit{
                grid-column: 1 / 3;
            }
        }
    }
}