@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.noDataContainer{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: spacing(1.5);
    & > .title {
        font-size: $normalFontSize;
        font-weight: 500;
        color: $fontColor;
        margin: 0;
    }
    & > .text {
        font-size: $extraSmallFontSize;
        color: $fontColor;
        max-width: 320px;
        text-align: center;
        margin: 0;
    }
    & > .image {
        margin: 0 0 spacing(3);
        height: 200px;
    }
}