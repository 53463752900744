@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.suspenseLoader{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: spacing(4);
    flex: 1;
    padding: spacing(1);
    background: $thirdBackgroundColor;
    & .logo{
        height: 36px;
    }
    & .loader{
        width: 140px;
        background-color: $skeletonBackgroundColor;
        & .barColor{
            background-color: $backgroundColor;
        }
    }
}