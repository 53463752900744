@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';

.payments{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: spacing(1.5);
    margin: spacing(1.5);
    width: 100%;
}
.container{
    display: flex;
    flex-direction: column;
    gap: spacing(3);
    padding: spacing(2) spacing(2) spacing(4);
    background: $backgroundColor;
    & .loader{
        flex: 1;
        margin: auto;
    }
    & > .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: spacing(4);
        & .iconButton{
            font-size: $mediumFontSize;
        }
    }
    & > .content{
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: spacing(3);
        & > .section{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-flow: row;
            gap: spacing(2);
            & .title{
                font-size: $normalFontSize;
                font-weight: 500;
                color: $fontColor;
                margin: 0;
                text-align: start;
                grid-column: 1 / 3;
            }
        }
    }
}