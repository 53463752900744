@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.loader{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: spacing(2);
    & .loader{
        width: 28px !important;
        height: 28px !important;
        color: $fontColor;
    }
    & .text{
        font-size: $smallFontSize;
        font-weight: 500;
        color: $fontColor;
        margin: 0;
    }
}