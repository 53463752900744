@import '../../../../styles/functions.scss';

.skeletonContainer{
    display: flex;
    flex-direction: column;
    gap: spacing(2);
    & .title{
        font-size: $mediumFontSize;
        width: 150px;
    }
    & .content{
        display: flex;
        flex-direction: column;
        gap: spacing(1);
        & .item{
            font-size: $mediumFontSize;
            max-width: 200px;
        }
    }
}