@supports (--css: variables) {
    [data-theme='light'] {
        --primaryColor: #4874D0;
        --primaryColorHover: #3d62b3;

        --successColor: #4caf50;
        --warningColor: #c8a60b;
        --errorColor: #DF4956;

        --greenColor: #54bf43;
        --blueColor: #4874d0;
        --grayColor: #6b6b6b;

        --backgroundColor: #FCFCFC;
        --secondBackgroundColor: #DCE1E5;
        --thirdBackgroundColor: #1A1C21;
        --fourBackgroundColor: #85888A;
        --fiveBackgroundColor: #F3F3F3;
        --sixBackgroundColor: #E4E8EF;

        --borderColor: #DCE1E5;
        --secondBorderColor: #D7D7D7;

        --fontColor: #3A3F44;
        --mainFontColor: #1A1C21;
        --secondFontColor: #FCFCFC;
        --thirdFontColor: #8E8E8E;

        --skeletonBackgroundColor: #18181d;     
        --borderRadius: 6px;   
        --defaultPadding: 8px;
    }
    [data-theme='dark'] {
        --primaryColor: #F6AF19;
        --primaryColorHover: #d09008;

        --successColor: #4caf50;
        --warningColor: #c8a60b;
        --errorColor: #DF4956;

        --pinkColor: 208, 72, 194;
        --redColor: 208, 72, 72;
        --greenColor: 84, 191, 67;
        --blueColor: 57, 120, 156;
        --lightBlueColor: 72, 200, 208;
        --orangeColor: 237, 149, 68;
        --grayColor: 107, 107, 107;

        --backgroundColor: #fafafa;
        --secondBackgroundColor: #fafafa;
        --thirdBackgroundColor: #DCE1E5;
        --fourBackgroundColor: #85888A;
        --fiveBackgroundColor: #F3F3F3;
        --sixBackgroundColor: #E4E8EF;
        
        --borderColor: #DCE1E5;
        --secondBorderColor: #DCE1E5;

        --fontColor: #262d3d;
        --mainFontColor: #262d3d;
        --secondFontColor: #212121;
        --thirdFontColor: #9c9c9d;

        --skeletonBackgroundColor: #e0e0e0;
        --borderRadius: 6px;
        --defaultPadding: 8px;
    }
}