@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.navBarContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 86px;
    padding: 0 spacing(1.5) 0 spacing(3.15);
    background-color: $thirdBackgroundColor;
    z-index: 1000;
    & > .linzeLink {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: fit-content;
        & .logo{
            height: 36px;
        }
    }
    & .menu{
        display: none;
        & .icon{
            font-size: $largeFontSize;
            color: $secondFontColor;
        }
    }
    & .notifications{
        position: relative;
        & .dropdown{
            padding: spacing(5) spacing(3);
            right: -10px;
            min-height: 100px;
            max-height: 500px;
        }
        & .unReaded{
            position: absolute;
            top: 2px;
            right: 6px;
            & .badge{
                background: $errorColor;
            }
        }
    }
    & .profile{
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: spacing(1) spacing(2);
        background: #242525;
        border-radius: $borderRadius;
        &.open{
            & .icon{
                transform: rotate(180deg);
            }
        }
        & .data{
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: spacing(0.5);
            margin: 0 0 0 spacing(2);
            font-weight: 400;
            & .name{
                font-size: 14px;
                color: #FCFCFC;
            }
            & .role{
                font-size: 12px;
                color: #8E8E8E;
            }
        }
        & .icon{
            width: 32px;
            color: $secondFontColor;
        }
        & .image{
            width: 28px;
            height: 28px;
            border: 2px solid $backgroundColor;
            border-radius: 100px;
            object-fit: cover;
        }
    }
    & .bkgr{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        visibility: hidden;
        opacity: 0;
        background: rgba($color: #000000, $alpha: 0.3);
        z-index: 10;
    }
}

@media screen and (max-width: 1024px) {
    .navBarContainer {
        padding: 0 spacing(3.25);
        & .menu{
            display: flex;
        }
        & .region{
            flex-direction: column;
            gap: spacing(3);
            padding: spacing(1.5) spacing(2);
            min-width: 166px;
            border-radius: 6px;
            & p{
                flex-direction: column;
                align-items: start;
            }
        }
        & > .navBarLabels {
            position: fixed;
            top: 0;
            right: -100%;
            bottom: 0;
            flex-direction: column-reverse;
            justify-content: start;
            padding: spacing(6) spacing(1);
            gap: 0;
            width: 240px;
            background: $thirdBackgroundColor;
            z-index: 11;
            transition: 0.3s ease-in-out;
            &.open{
                right: 0;
                transition: 0.3s ease-in-out;
            }
            & .link{
                padding: spacing(2.5) spacing(2);
                font-size: $extraSmallFontSize;
            }
        }
        & .bkgr{
            &.open{
                visibility: visible;
                opacity: 1;
                transition: 0.3s ease-in-out;
            }
        }
        & .activeLink{
            text-shadow: 0 0 1px $secondFontColor, 0 0 1px $secondFontColor !important;
        }
    }
}