@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: $secondBackgroundColor;
	& .appContent{
		flex: 1;
		display: flex;
		flex-direction: row;
	}
}

*::-webkit-scrollbar{
	width: 8px;
	height: 8px;
	background: none;
}
*::-webkit-scrollbar-thumb{
	width: 8px;
	height: 8px;
	background: $thirdFontColor;
	border-radius: 100px;
}