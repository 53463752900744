@import "../../../../styles/variables.scss";
@import "../../../../styles/functions.scss";

.sidebarContent{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: spacing(3);
    padding: 0 spacing(3) spacing(3);
    width: 450px;
    & .inputs{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-auto-flow: row;
        gap: spacing(3) spacing(2);
        & .full{
            grid-column: span 6;
        }
        & .four{
            grid-column: span 4;
        }
        & .half{
            grid-column: span 3;
        }
        & .two{
            grid-column: span 2;
        }
    }
    & .sendInvitation{
        margin: auto auto 0;
    }
}