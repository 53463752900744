@import "./variables.scss";

@mixin table{
    &.loading{
      & .row{
        background: $fiveBackgroundColor;
      }   
    }
    & .head{
        background: $fiveBackgroundColor !important;
        border-radius: $borderRadius;
        & .cell{
            font-size: $smallFontSize;
            font-weight: 500;
            color: $fontColor;
            border: none;
            padding: spacing(2.5) spacing(1);
            text-align: start;
            &:first-child{
                border-top-left-radius: $borderRadius;
                border-bottom-left-radius: $borderRadius;
            }
            &:last-child{
                border-top-right-radius: $borderRadius;
                border-bottom-right-radius: $borderRadius;
            }
        }
    }
    & .row{
        &:hover{
            background-color: $fiveBackgroundColor !important;
        }
        & .cell{
            padding: spacing(1.5) spacing(1);
            text-align: start;
            &.bold{
                & p{
                    font-weight: 500 !important;
                }
            }
            & .action{
                display: flex;
                align-items: center;
                gap: spacing(0.5);
                height: 100%;
                & .button{
                    transition: 0.15s all ease-in-out;
                    opacity: 0.7;
                    &:hover{
                        opacity: 1;
                        transition: 0.15s all ease-in-out;
                    }
                }
            }
            & p{
              font-size: $extraSmallFontSize;
              font-weight: 400;
              color: $fontColor;
              margin: 0;
            }
        }
    }
}

@mixin chips{
  &.green{
      color: #3BA32B;
      background: #E7FFE8;
  }
  &.red{
      color: #D03F62;
      background: #FFE7E8;
  }
  &.blue{
      color: #3F82D0;
      background: #E7EEFF;
  }
  &.yellow{
      color: #D0853F;
      background: #FFEFE7;
  }
  &.gray{
      color: #6b6b6b;
      background: #E1E1E1;
  }
}

@mixin loaderDots{
    &.loader{
        font-size: $extraExtraSmallFontSize;
        font-weight: 400;
        color: $thirdFontColor;
        margin: 0;
        & span[class^="dot-"]{
          opacity: 0;
        }
        & .dotOne{
          animation: dot-one 2.5s infinite linear
        }
        & .dotTwo{
          animation: dot-two 2.5s infinite linear
        }
        & .dotThree{
          animation: dot-three 2.5s infinite linear
        }
      }
    @keyframes dot-one{
      0%{
        opacity: 0;
      }
      15%{
        opacity: 0;
      }
      25%{
        opacity: 1;
      }
      100%{
        opacity: 1;
      }
    }
      
    @keyframes dot-two{
      0%{
        opacity: 0;
      }
      25%{
        opacity: 0;
      }
      50%{
        opacity: 1;
      }
      100%{
        opacity: 1;
      }
    }
      
    @keyframes dot-three{
      0%{
        opacity: 0;
      }
      50%{
        opacity: 0;
      }
      75%{
        opacity: 1;
      }
      100%{
        opacity: 1;
      }
    }
}