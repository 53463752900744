@import '../../../../styles/functions.scss';

.skeletonContainer{
    display: flex;
    flex-direction: column;
    gap: spacing(2);
    & .title{
        font-size: $mediumFontSize;
        width: 150px;
    }
    & .content{
        display: flex;
        flex-wrap: wrap;
        max-width: 832px;
        gap: spacing(4) spacing(6);
    }
}