//colors
$primaryColor: var(--primaryColor);
$primaryColorHover: var(--primaryColorHover);
$successColor: var(--successColor);
$errorColor: var(--errorColor);
$warningColor: var(--warningColor);
$greenColor: var(--greenColor);
$blueColor: var(--blueColor);
$grayColor: var(--grayColor);
$backgroundColor: var(--backgroundColor);
$secondBackgroundColor: var(--secondBackgroundColor);
$thirdBackgroundColor: var(--thirdBackgroundColor);
$fourBackgroundColor: var(--fourBackgroundColor);
$fiveBackgroundColor: var(--fiveBackgroundColor);
$sixBackgroundColor: var(--sixBackgroundColor);
$borderColor: var(--borderColor);
$secondBorderColor: var(--secondBorderColor);
$fontColor: var(--fontColor);
$mainFontColor: var(--mainFontColor);
$secondFontColor: var(--secondFontColor);
$thirdFontColor: var(--thirdFontColor);
$skeletonBackgroundColor: var(--skeletonBackgroundColor);

$borderRadius: var(--borderRadius);
$defaultPadding: var(--defaultPadding);

$extraExtraSmallFontSize: 12px;
$extraSmallFontSize: 14px;
$smallFontSize: 16px;
$normalFontSize: 18px;
$mediumFontSize: 20px;
$mediumLargeFontSize: 24px;
$largeFontSize: 28px;
$extraLargeFontSize: 32px;

$fontFamily: Montserrat, sans-serif;
$boxshadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);

:export {
    primaryColor: $primaryColor;
    primaryColorHover: $primaryColorHover;
    greenColor: $greenColor;
    blueColor: $blueColor;
    grayColor: $grayColor;
    borderRadius: $borderRadius;
    backgroundColor: $backgroundColor;
    secondBackgroundColor: $secondBackgroundColor;
    defaultPadding: $defaultPadding;
    fontColor: $fontColor;
    mainFontColor: $mainFontColor;
    thirdFontColor: $thirdFontColor;
    secondFontColor: $secondFontColor;
    thirdBackgroundColor: $thirdBackgroundColor;
    skeletonBackgroundColor: $skeletonBackgroundColor;
    fontFamily: $fontFamily;
    secondBorderColor: $secondBorderColor;
    borderColor: $borderColor;
}

